/**
 * Tag listing page.
 */

import React from "react"
import PropTypes from "prop-types"

// Utilities
import kebabCase from "lodash/kebabCase"

// Components
import {Helmet} from "react-helmet"
import {Link, graphql} from "gatsby"
import Layout from "../components/layout";
import Sidebar from "../components/sidebar";

const TagsPage = ({
                      data: {
                          allMarkdownRemark: {group},
                          site: {
                              siteMetadata: {title},
                          },
                      },
                  }) => (
    <Layout location={title} title={title}>
        <Helmet title={title}/>

        <Sidebar/>

        <div className='content main-content'>
            <h1>Tags</h1>
            <ul className='tag-list'>
                {group.map(tag => (
                    <li key={tag.fieldValue}>
                        <Link to={`/tags/${kebabCase(tag.fieldValue)}/`} className='tag-link'>
                            {tag.fieldValue} ({tag.totalCount})
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    </Layout>
);

TagsPage.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            group: PropTypes.arrayOf(
                PropTypes.shape({
                    fieldValue: PropTypes.string.isRequired,
                    totalCount: PropTypes.number.isRequired,
                }).isRequired
            ),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                title: PropTypes.string.isRequired,
            }),
        }),
    }),
};

export default TagsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
